const messages = {
  toge: `Ense`,
  ther: `mble`,
  forHumanity: `Pour l'humanité`,
  subtitle: `Beslogic est fière de lancer son appel à projet philanthropique. Nous avons toujours rêvé de redonner à la communauté pour contribuer au bien de l’humanité à travers la collaboration.`,
  description: `Notre équipe d’experts en développement de logiciels et en intelligence artificielle vous propose de concrétiser votre projet technologique, et ce, sans frais!
Dans le cadre de cet appel à projet philanthropique, nous collaborons avec votre équipe pour donner vie à vos idées.
Vous n’avez qu’à compléter le formulaire suivant et/ou nous envoyer une courte vidéo présentant votre entreprise, votre mission et comment celle-ci contribue à faire de notre planète un endroit meilleur, et décrire votre projet technologique. Joignons nos forces pour développer les solutions numériques qui révolutionneront le monde de demain!
Prêts à relever la mission? C’est parti!`,
  submitYourProject: `Soumettez votre projet`,
  preferredLanguage: `Langue de communication`,
  fullName: `Prénom et nom complet`,
  organizationName: `Nom de l’entreprise`,
  email: `Adresse courriel`,
  phoneNumber: `Numéro de téléphone`,
  acquisitionSource: `Comment avez-vous entendu parler de l'initiative de Beslogic`,
  videoUpload: `Vidéo de présentation de l'entreprise`,
  contact: `CONTACT`,
  support: `SUPPORT`,
  services: `SERVICES`,
  serviceSD: `Développement logiciel sur mesure`,
  servicePD: `Développement de produits logiciels`,
  serviceAI: `Intelligence artificielle`,
  serviceDT: `Transformation numérique`,
  send: `Envoyer`,
  videoErrorNoVideo: `Veuillez sélectionner une vidéo.`,
  videoErrorUnsupprortedFileType: `Format de fichier "{fileType}" non supporté. Veuillez sélectionner une vidéo.`,
  videoErrorTooLarge: `'La vidéo est trop volumineuse. Taille maximum: 1GB.'`,
  formSentTitle: `Merci d'avoir soumis votre candidature!`,
  formSentdescription: `Nous communiquerons avec vous sous peu.`,
  optionSocialMedia: `Médias sociaux`,
  optionTraditionalMedia: `Médias traditionnels`,
  optionPersonalReference: `Référence personnelle`,
  optionWebSite: `Site web`,
  optionOther: `Autre`,
};
export default messages;
