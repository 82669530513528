import type baseMessages from "./fr";

const messages: typeof baseMessages = {
  toge: `Toge`,
  ther: `Ther`,
  forHumanity: `For Humanity`,
  subtitle: `Beslogic is proud to launch its philanthropic project request. We have always dreamed of giving back to the community to contribute to the well-being of humanity through collaboration.`,
  description: `Our team of experts in software development and artificial intelligence offers to make your technology project a reality, at zero cost!
In this philanthropic project request, we work with your team to bring your ideas to life.
To submit your application, you just need to simply complete the following form and/or send us a short video presenting your company, your mission and how it contributes to making our planet a better place, and describe your technology project. Let's join forces to develop the digital solutions that will revolutionize the world of tomorrow!
Ready to take on the mission? Let's get started!`,
  submitYourProject: `Submit your project`,
  preferredLanguage: `Communication language`,
  fullName: `Full name`,
  organizationName: `Company name`,
  email: `Email address`,
  phoneNumber: `Phone number`,
  acquisitionSource: `How did you hear about Beslogic's initiative?`,
  videoUpload: `Company presentation video`,
  contact: `CONTACT`,
  support: `SUPPORT`,
  services: `SERVICES`,
  serviceSD: `Custom Software Development`,
  servicePD: `Software Product Development`,
  serviceAI: `Artificial Intelligence`,
  serviceDT: `Digital Transformation`,
  send: `Send`,
  videoErrorNoVideo: `Please choose a video.`,
  videoErrorUnsupprortedFileType: `Unsupported file type "{fileType}". Please select a video.`,
  videoErrorTooLarge: `'Video too large. Please keep it under 1GB.'`,
  formSentTitle: `Thank you for your application!`,
  formSentdescription: `We will contact you soon.`,
  optionSocialMedia: `Social Media`,
  optionTraditionalMedia: `Traditional Media`,
  optionPersonalReference: `Personal Reference`,
  optionWebSite: `Website`,
  optionOther: `Other`,
};

export default messages;
