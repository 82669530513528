import './Slogan.css'

import { SvgIcon } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ReactComponent as ChevronRight } from '../assets/icon-chevron--right.svg'

const Slogan = () => {
  return (
    <div className='content'>
      <div className='page_slogan_container'>
        <div className='page_slogan_col'>
          <div className='page_slogan_image'>
            <div>
              <picture>
                <img alt='Beslogic' src='hero-home-group.png' />
              </picture>
            </div>
          </div>
          <div className='page_slogan_image_words'>
            <div>
              <FormattedMessage id='toge' />
            </div>
            <div>
              <FormattedMessage id='ther' />
            </div>
          </div>
        </div>
        <div className='page_slogan_text'>
          <SvgIcon className='chevron_right' component={ChevronRight} inheritViewBox />
          <div>
            <FormattedMessage id='forHumanity' />
          </div>
        </div>
      </div>
      <div className='page_subtitle_container'>
        <div className='page_subtitle_container-left' />
        <h1 className='page_subtitle_container-right'>
          <FormattedMessage id='subtitle' />
        </h1>
      </div>
    </div>
  )
}
export default Slogan
