import './Aside.css'

import { SvgIcon } from '@mui/material'

import { ReactComponent as Facebook } from '../assets/icon-facebook.svg'
import { ReactComponent as Instagram } from '../assets/icon-instagram.svg'
import { ReactComponent as Linkedin } from '../assets/icon-linkedin.svg'
import logoVertical from '../assets/logo-vertical.svg'
import sideBg from '../assets/side-bg.png'

const Aside = () => {
  return (
    <aside className='aside' id='aside'>
      <div className='side_content' style={{ backgroundImage: `url(${sideBg})` }}>
        <a className='logo' href='/'>
          <picture>
            <img alt='' src={logoVertical} />
          </picture>
        </a>
        <div className='side_block-socials'>
          <div className='socials'>
            <a href='https://www.facebook.com/beslogic' rel='noreferrer' target='_blank'>
              <SvgIcon className='icon' component={Facebook} inheritViewBox />
            </a>
            <a href='https://www.instagram.com/beslogic_/' rel='noreferrer' target='_blank'>
              <SvgIcon className='icon' component={Instagram} inheritViewBox />
            </a>
            <a href='https://ca.linkedin.com/company/beslogic' rel='noreferrer' target='_blank'>
              <SvgIcon className='icon' component={Linkedin} inheritViewBox />
            </a>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default Aside
