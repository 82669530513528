import './Footer.css'

import { FormattedMessage } from 'react-intl'

const Footer = () => {
  return (
    <footer className='footer' id='footer'>
      <div className='sc_con'>
        <div className='sc_col'>
          <div className='footer_item'>
            <h3>
              <FormattedMessage id='contact' />
            </h3>
            <p>
              <a href='tel:1-844-237-5644'>1 844 BES-LOGIC</a>
              <br />
              <a href='mailto:info@beslogic.com'>info@beslogic.com</a>
              <br />
              <a
                href='https://www.google.com/maps/place/Beslogic+Inc./@45.4835645,-73.5809397,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc910a4c521c58d:0xf8a9f3c47157467b!8m2!3d45.4835645!4d-73.5809397'
                rel='noreferrer'
                target='_blank'
              >
                721 Walker avenue, Office 200 Montréal, QC H4C 2H5
              </a>
            </p>
          </div>
          <div className='footer_item'>
            <h3>
              <FormattedMessage id='support' />
            </h3>
            <p>
              <a href='tel:1-844-237-5644'>1 844 BES-LOGIC</a>
            </p>
          </div>
        </div>
        <div className='sc_col'>
          <div className='footer_item'>
            <h3>
              <FormattedMessage id='services' />
            </h3>
            <p>
              <a href='https://www.beslogic.com/custom-software-development' target='beslogic'>
                <FormattedMessage id='serviceSD' />
              </a>
              <br />
              <a href='https://www.beslogic.com/software-product-development' target='beslogic'>
                <FormattedMessage id='servicePD' />
              </a>
              <br />
              <a href='https://www.beslogic.com/artificial-intelligence' target='beslogic'>
                <FormattedMessage id='serviceAI' />
              </a>
              <br />
              <a href='https://www.beslogic.com/digital-transformation' target='beslogic'>
                <FormattedMessage id='serviceDT' />
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
