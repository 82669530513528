import './App.css'

import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'
import { useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import type { ResolvedIntlConfig } from 'react-intl'
import { FormattedMessage, IntlProvider } from 'react-intl'

import enMessages from '../lang/en'
import frMessages from '../lang/fr'
import Aside from './Aside'
import Footer from './Footer'
import PhilantropyForm from './PhilantropyForm'
import Slogan from './Slogan'

const browserHistory = createBrowserHistory()
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=0c7323e8-5501-442d-83e3-8359b2ef4e85;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
})
appInsights.loadAppInsights()

const SUPPORTED_LANGUAGES: Record<string, true | undefined> = {
  fr: true,
  en: true,
} as const

const userDefaultLanguage = navigator.languages
  .map(language => String(language.split('-')[0]))
  .find(language => SUPPORTED_LANGUAGES[language]) ?? 'fr'

const messages: Record<
  ResolvedIntlConfig['locale'],
  ResolvedIntlConfig['messages']
> = {
  fr: frMessages,
  en: enMessages,
}

const App = () => {
  const [locale, setLocale] = useState(userDefaultLanguage)
  const [formSent, setFormSent] = useState(false)

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey='6Lf2NF8jAAAAAEsGiR815d5YoE6JKqmtb9dsDQtq'
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <IntlProvider
        defaultLocale={userDefaultLanguage}
        locale={locale}
        messages={messages[locale]}
      >
        <div className='wrapper'>
          <Aside />
          <div className='container'>
            <Slogan />
            {formSent
              ? (
                <div className='form_sent'>
                  <h2>
                    <FormattedMessage id='formSentTitle' />
                  </h2>
                  <p>
                    <FormattedMessage id='formSentdescription' />
                  </p>
                </div>
              )
              : (
                <PhilantropyForm
                  defaultLanguage={userDefaultLanguage}
                  onFormSent={() => setFormSent(true)}
                  onLanguageChange={setLocale}
                />
              )}
            <Footer />
          </div>
        </div>
      </IntlProvider>
    </GoogleReCaptchaProvider>
  )
}

export default withAITracking(reactPlugin, App)
