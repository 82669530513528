/* eslint-disable no-alert */
import "./PhilantropyForm.css";

// eslint-disable-next-line unicorn/no-keyword-prefix
import classNames from "classnames";
import type { ChangeEvent, MouseEvent } from "react";
import { createRef, useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormattedMessage, useIntl } from "react-intl";

// Note: GB, not GiB
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const GB1 = 1000 ^ 3;

const PLACEHOLDER =
  // eslint-disable-next-line max-len
  "--------------------------------------------------------------------------------------------------------------------------------------------------------------------";

const PhilantropyForm = (props: {
  defaultLanguage: string;
  onLanguageChange: (value: string) => void;
  onFormSent: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [language, setLanguage] = useState(props.defaultLanguage);
  const [fullName, setFullName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [acquisitionSource, setAcquisitionSource] = useState("SocialMedia");
  const [googleReCaptchaToken, setGoogleReCaptchaToken] = useState("");
  const [video, setVideo] = useState<File | undefined>();
  const [videoError, setVideoError] = useState<string | null>(null);
  const videoInputRef = createRef<HTMLInputElement>();
  const intl = useIntl();
  const clearVideo = () => {
    setVideo(undefined);
    if (videoInputRef.current) {
      videoInputRef.current.value = "";
    }
  };

  const handlelanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.currentTarget.value);
    props.onLanguageChange(event.currentTarget.value);
  };
  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    const errorMessage = validateVideo(file);
    if (errorMessage) {
      clearVideo();
      setVideoError(errorMessage);
    } else {
      setVideo(file);
      setVideoError(null);
    }
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.info("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submitForm");
    // Do whatever you want with the token
    setGoogleReCaptchaToken(token);
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify().catch(console.error);
  }, [handleReCaptchaVerify]);

  const handleFormSubmit: (event: MouseEvent<HTMLButtonElement>) => boolean = (
    event
  ) => {
    const form = event.currentTarget.parentElement as HTMLFormElement;
    let valid = form.reportValidity() && googleReCaptchaToken !== "";
    // If invalid, we let the form report its own errors
    // IMPORTANT: If valid, we must prevent the form from sending, so we can do our own validation
    if (valid) {
      event.preventDefault();
    }

    const videoErrorMessage = validateVideo(video);
    if (videoErrorMessage) {
      clearVideo();
      // If the base form was already invalid, no need to show this error.
      // It's also unlikely to happen with the form's HTML5 validations
      setVideoError(videoErrorMessage);

      valid = false;
    }

    if (valid) {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("organizationName", organizationName);
      formData.append("email", email);
      formData.append("preferredLanguage", language);
      formData.append("acquisitionSource", acquisitionSource);
      formData.append("phoneNumber", phoneNumber);
      formData.append("videoFileName", video?.name ?? "");
      formData.append("videoFileContentType", video?.type ?? "");
      fetch(
        "https://beslogicphilantropylanding.azurewebsites.net/api/UploadFormFunction?code=VxNP-DQ6f9nK8zVWlia8AvyUn2BA4kep4mQ3MFWeDcgXAzFukcxQ3w==",
        {
          method: "POST",
          body: formData,
        }
      )
        .then(async (response) => {
          if (response.ok) {
            const sas = await response.text();

            await fetch(sas, {
              method: "PUT",
              body: video,
              headers: {
                "x-ms-blob-type": "BlockBlob",
              },
            });

            props.onFormSent();
          } else {
            response
              .text()
              .then((text) => alert(`${text} (${response.statusText})`))
              .catch((error: unknown) =>
                alert(`${(error as Error).message} (${response.statusText})`)
              );
          }
        })
        .catch((error: unknown) => alert((error as Error).message))
        .finally(() => setIsSubmitting(false));
    }

    return false;
  };

  const validateVideo = (file: File | undefined) => {
    if (!file) {
      return intl.formatMessage({ id: "videoErrorNoVideo" });
    }
    const fileType = file.type.split("/")[0];
    if (fileType !== "video") {
      return intl.formatMessage(
        { id: "videoErrorUnsupprortedFileType" },
        { fileType }
      );
    }
    if (file.size < GB1) {
      return intl.formatMessage({ id: "videoErrorTooLarge" });
    }

    return null;
  };

  /* eslint-disable camelcase */
  const formContainerClassNames = classNames({
    page_description_form: true,
    disabled: isSubmitting,
  });

  const fileSelectionClassNames = classNames({
    "form_field--file": true,
    "form_field--file-error": videoError !== null,
  });
  /* eslint-ensable camelcase */

  return (
    <div className="form_container">
      <div className="page_description_container">
        <div className="page_description_title">
          <p>
            <FormattedMessage id="description" />
          </p>
        </div>
        <div className={formContainerClassNames}>
          <form
            className="form"
            encType="multipart/form-data"
            id="philantropy-form"
            method="post"
            onSubmit={(event) => event.preventDefault()}
          >
            <div className="form_field">
              <label htmlFor="preferredLanguage">
                <FormattedMessage id="preferredLanguage" />
              </label>
              <select
                className="custom-select"
                id="preferredLanguage"
                name="preferredLanguage"
                onChange={handlelanguageChange}
                required
                value={language}
              >
                <option value="fr">Français</option>
                <option value="en">English</option>
              </select>
            </div>
            <div className="form_field">
              <label htmlFor="fullName">
                <FormattedMessage id="fullName" />
              </label>
              <input
                id="fullName"
                name="fullName"
                onChange={(event) => setFullName(event.target.value)}
                placeholder={PLACEHOLDER}
                required
                value={fullName}
              />
            </div>
            <div className="form_field">
              <label htmlFor="organizationName">
                <FormattedMessage id="organizationName" />
              </label>
              <input
                id="organizationName"
                name="organizationName"
                onChange={(event) => setOrganizationName(event.target.value)}
                placeholder={PLACEHOLDER}
                required
                value={organizationName}
              />
            </div>
            <div className="form_field">
              <label htmlFor="email">
                <FormattedMessage id="email" />
              </label>
              <input
                id="email"
                name="email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder={PLACEHOLDER}
                required
                type="email"
                value={email}
              />
            </div>
            <div className="form_field">
              <label htmlFor="phoneNumber">
                <FormattedMessage id="phoneNumber" />
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                onChange={(event) => setPhoneNumber(event.target.value)}
                placeholder={PLACEHOLDER}
                required
                type="tel"
                value={phoneNumber}
              />
            </div>
            <div className="form_field">
              <label htmlFor="acquisitionSource">
                <FormattedMessage id="acquisitionSource" />
              </label>
              <select
                className="custom-select"
                id="acquisitionSource"
                name="acquisitionSource"
                onChange={(option) => setAcquisitionSource(option.target.value)}
                required
                value={acquisitionSource}
              >
                <option value="SocialMedia">
                  <FormattedMessage id="optionSocialMedia" />
                </option>
                <option value="TraditionalMedia">
                  <FormattedMessage id="optionTraditionalMedia" />
                </option>
                <option value="PersonalReference">
                  <FormattedMessage id="optionPersonalReference" />
                </option>
                <option value="WebSite">
                  <FormattedMessage id="optionWebSite" />
                </option>
                <option value="Other">
                  <FormattedMessage id="optionOther" />
                </option>
              </select>
            </div>
            <div className={fileSelectionClassNames}>
              <label htmlFor="videoUpload">
                <FormattedMessage id="videoUpload" />
              </label>
              <input
                accept="video/*"
                id="videoUpload"
                name="videoUpload"
                onChange={handleVideoChange}
                ref={videoInputRef}
                required
                type="file"
              />
            </div>
            {!isSubmitting && (
              <button
                className="form-button h5"
                disabled={!googleReCaptchaToken}
                onClick={handleFormSubmit}
                type="submit"
              >
                <div className="form-button_text">
                  <FormattedMessage id="send" />
                </div>
              </button>
            )}
            {isSubmitting && (
              <div className="cube animated" id="cube">
                <div className="s s-1" />
                <div className="s s-2" />
                <div className="s s-3" />
                <div className="s s-4" />
                <div className="s s-5" />
                <div className="s s-6" />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default PhilantropyForm;
